<template>
<div>
  <div class="antd-panel" style="position: relative;margin-top: 10px">
    <a-button type="primary" v-print="print" style="position: absolute;right: 27px;z-index: 99;">打印</a-button>
    <div  id="printDiv">
      <a-card :bordered="false" class="top-infor">
         <table class="table">
            <tr>
              <th>考生:</th><td>{{courseExamDetailsData.traineeName}}</td>
              <th>得分:</th><td>{{courseExamDetailsData.score}}</td>
              <th>答对多少题:</th><td>{{courseExamDetailsData.isTrue}}</td>
              <th>总题数:</th><td>{{courseExamDetailsData.totalCount}}</td>
              <th>答题开始时间:</th><td>{{$moment(courseExamDetailsData.startTime).format('YYYY-MM-DD HH:mm:ss')}}</td>
            </tr>
          </table>
        <!-- <div class="title">提示：不限考试次数，考试时长120分钟，及格分数是总分的60%；考试过程中可以修改完成题目的答案；如果考试异常中断，请退出并及时按同样步骤进入，继续考试</div> -->
        <a-row type="flex" justify="space-between" align="middle">
          <a-col flex="auto">
            <a-row type="flex" :gutter="30" class="infor">
              <a-col><span>交卷时间:{{$moment(courseExamDetailsData.endTime).format('YYYY-MM-DD HH:mm:ss')}}</span></a-col>
              <a-col><span>考试总分:100分</span></a-col>
              <a-col><span>通过分数:{{courseExamDetailsData.examPassScore}}</span></a-col>
            </a-row>
          </a-col>
          <a-col flex="250px" class="set-size">
            字号:
            <span :class="['big',{'current':setSize==='set-big'}]" @click="setSize = 'set-big'">大字</span>
            <span :class="['middle',{'current':setSize==='set-middle'}]" @click="setSize = 'set-middle'">中字</span>
            <span :class="['default',{'current':setSize==='set-default'}]" @click="setSize = 'set-default'">正常</span>
          </a-col>
        </a-row>
      </a-card>
      <a-card :bordered="false" style="border-top: 1px solid #eee">
          <a-row type="flex">
            <a-col flex="auto" style="font-size:18px;">
              <dl :class="['exam-list',setSize]">
                <template v-for="(item, index) in courseExamDetailsData.questionVos">
                  <dt :id="'sno-'+(index+1)" :key="index">{{item.sno}}.【{{item.questionTypeName}}】{{item.question}}</dt>
                  <dd :key="index+'o'">
                    <div v-if="item.picture"><img class="pic" :src="$imgBaseUrl + item.picture" alt=""></div>
                    <a-checkbox-group v-if="item.questionType === 2" :default-value="valueToArrayFilter(item.studentAnswer)" :disabled="timeActive">
                      <template v-for="(itemSub, indexSub) in item.items">
                        <a-checkbox :key="indexSub" :value="itemSub.itemType" :style="radioStyle">
                        {{itemSub.itemType}}：
                        <template v-if="(item.answer.indexOf(itemSub.itemType) == -1) && (item.studentAnswer.indexOf(itemSub.itemType) != -1)">
                          <span style="color: red">{{itemSub.itemContent}}</span>
                        </template>
                        <template v-else>
                          <span>{{itemSub.itemContent}}</span>
                        </template>
                        </a-checkbox>
                        <img v-if="itemSub.itemImg" :key="indexSub+'img'" class="pic-item" :src="$imgBaseUrl + itemSub.itemImg" alt="">
                      </template>
                    </a-checkbox-group>
                    <a-radio-group v-else :default-value="item.studentAnswer" :disabled="timeActive">
                      <template v-for="(itemSub, indexSub) in item.items">
                        <a-radio :key="indexSub" :value="itemSub.itemType" :style="radioStyle">
                        {{itemSub.itemType}}：
                        <template v-if="(item.answer.indexOf(itemSub.itemType) == -1) && (item.studentAnswer.indexOf(itemSub.itemType) != -1)">
                          <span style="color: red">{{itemSub.itemContent}}</span>
                        </template>
                        <template v-else>
                          <span>{{itemSub.itemContent}}</span>
                        </template>
                        </a-radio>
                        <img v-if="itemSub.itemImg" :key="indexSub+'img'" class="pic-item" :src="$imgBaseUrl + itemSub.itemImg" alt="">
                      </template>
                    </a-radio-group>
                    <div class="answer">
                      <div>正确答案：
                        <template v-if="item.answer != item.studentAnswer">
                          <span style="color: red">{{item.answer}}</span>
                        </template>
                        <template v-else>
                          <span>{{item.answer}}</span>
                        </template>
                      </div>
                      <div>题目解析：{{item.answerAnalyze}}</div>
                    </div>
                  </dd>
                </template>
              </dl>
            </a-col>
          </a-row>
        </a-card>
        </div>
          <a-col flex="360px">
              <div class="right-box">
                  <ul class="select-all clearfix">
                      <li>
                          <span></span>
                          <span>正确</span>
                      </li>
                      <li class="select">
                          <span></span>
                          <span>错误</span>
                      </li>
                  </ul>
                  <ul class="select-list clearfix">
                      <template v-for="(item, index) in courseExamDetailsData.questionVos">
                        <li :key="index" :class="{ 'select' : $utils.stringSort(item.answer) != $utils.stringSort(item.studentAnswer)}"><a :href="'#sno-'+(index+1)">{{item.sno}}</a></li>
                      </template>
                  </ul>
              </div>
          </a-col>
      
  </div>
</div>
</template>

<script>
import {courseExamDetails} from '@/httpsAPI/course/index'
export default {
  name: 'Advanced',
  data () {
    return {
      print: {
        id:'printDiv',
        popTitle: ' ',  // 打印的标题
        // extraHead: '1111',  // 打印头部文字
        // preview: true, // 是否启动预览模式，默认是false
        // previewTitle: '预览的标题', // 打印预览的标题
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        marginLeft: 0
      },
      courseExamDetailsData: '',
      totalNum: '',
      setSize: 'set-default',
      timeActive: true
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'agree': '成功',
        'reject': '驳回'
      }
      return statusMap[status]
    },
  },
  created(){
    this.courseExamDetails()
  },
  mounted(){
    console.log(this.$utils.stringSort("D,B,C,A"))
    
    
  },
  computed: {
    valueToArrayFilter: function () {
      return  (data) => {
        if(data != null){
          return data.split(',')
        }
        else{
          return data
        }
      } 
    },
  },
  methods: {
    courseExamDetails () {
      let id = this.$route.query.id
      console.log({id:id})
      courseExamDetails(id).then(res=>{
        console.log(res)
        this.courseExamDetailsData = res.data
        this.totalNum = res.data.questionVos.length
      })
    },
  }
}
</script>

<style lang="less" scoped>
.table{
  // margin-bottom: 50px;
  th{
    font-weight: normal;
  }
  td{
    padding-right: 30px;
  }
}
.exam-list{
  dt{
    margin-bottom: 10px;
    margin-top: 10px;
    padding-right: 380px;
  }
  dd{
    padding-left:30px;
    .pic{
      height:  128px;
      padding: 3px;
      border: 1px solid #efefef;
    }
    .pic-item{
      height:  128px;
      padding: 3px;
      border: 1px solid #efefef;
      margin-left: 30px;
    }
    .answer{
      margin-top: 10px;
      line-height: 30px;
    }
  }
}
.set-default{
  dt{
    font-size: 14px;
  }
  dd *{
    font-size: 14px;
  }
}
.set-middle{
  dt{
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  dd *{
    font-size: 16px;
  }
}
.set-big{
  dt{
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  dd *{
    font-size: 18px;
  }
}
.submit-exam{
  text-align: center;
  margin-top:50px;
  .finish-btn{
    display: inline-block;
    background: #ccc;
    color: #fff;
    padding: 10px 80px;
    width: 80%;
    border-radius: 20px;
    margin-top: 20px;
    cursor: text;
  }
  .default{
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border: 1px #d9d9d9 solid;
    cursor: pointer;
  }
  .finish{
    background: #84c562;
    color: #fff;
    cursor: pointer;
  }
}
.right-box{
    position: fixed;
    right: 60px;
    bottom: 30px;
    width: 380px;
    background: #fff;
    padding: 10px 24px 30px 24px;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 0 10px #eee;
    .select-list{
      padding: 0;
        li{
            float: left;
            text-align: center;
            border-radius: 50%;
            margin: 3px;
            background: #84c562;
            border: 1px #84c562 solid;
            cursor: pointer;
            a{
                display: inline-block;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                color: #fff;
            }
        }
        .select{
            border: 1px #ff4d4f solid;
            background: #ff4d4f;
            a{
              color: #fff;
            }
        }
    }
    .select-all{
      padding: 0;
        li{
            float: left;
            margin: 10px;
            *{
              vertical-align:middle;
            }
            span:nth-child(1){
                display: inline-block;
                width: 10px;
                height: 10px;
                line-height: 10px;
                text-align: center;
                border-radius: 50%;
                background: #84c562;
                border: 1px #84c562 solid;
                cursor: pointer;
            }
            span:nth-child(2){
                display: inline-block;
                line-height: 20px;
                margin-left:10px;
            }
        }
        .select{
            span:nth-child(1){
                border: 1px #ff4d4f solid;
                background: #ff4d4f;
                color: #c56262;
            }
        }
    }
}
.top-infor{
  .title{
    margin-bottom: 10px;
  }
  .infor{
    line-height: 32px;
    font-weight: bold;
    *{
      vertical-align: middle;
    }
    i{
      font-size: 30px;
      color:#333
    }
  }
}
.set-size{
  // line-height: 32px;
  span{
    padding: 0 10px;
    cursor:pointer;
  }
  .default{
    font-size: 14px;
  }
  .middle{
    font-size: 16px;
  }
  .big{
    font-size: 18px;
  }
  .current{
    color: #84c562
  }
}
.right-box ul{
  list-style: none;
}
</style>